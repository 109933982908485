import loadScripts from 'load-scripts';
import { ToastProgrammatic as Toast } from 'buefy'
import { getRemoteConfigValue } from '@assets/js/remoteConfig';

import Dinero from 'dinero.js';

async function createSquare(enviroment) {

    if (window.Square) {
        return
    }

    const ENV = enviroment == 'sandbox' ? 'sandbox.' : '';
    await loadScripts(`https://${ENV}web.squarecdn.com/v1/square.js`);
    return;
}

async function getCredentials(enviroment) {

    var APPID = null;
    var LOCATIONID = null;

    if (enviroment == 'sandbox') {
        APPID = await getRemoteConfigValue('squareAppID_SANDBOX');
        LOCATIONID = await getRemoteConfigValue('squareLocationID_SANDBOX');
    } else {
        APPID = await getRemoteConfigValue('squareAppID');
        LOCATIONID = await getRemoteConfigValue('squareLocationID');
    }

    return { APPID: APPID.asString(), LOCATIONID: LOCATIONID.asString() };
}

async function handleSubmission(CHARGE, payments, paymentMethod, shouldVerify = false, user = null) {

    var token = await tokenize(paymentMethod);
    var verificationToken = null;

    if (shouldVerify) {
        verificationToken = await verifyBuyer(CHARGE, payments, token, user);
    }

    return {
        token: token,
        verificationToken: verificationToken
    };
}

async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();

    if (tokenResult.status == 'OK') {
        return tokenResult.token;
    }

    if (tokenResult.status == 'Cancel') {
        Toast.open({ message: 'Payment was canceled', type: 'is-danger', queue: false, duration: 5000, position: 'is-bottom' });
        throw new Error('Payment was canceled');
    }

    try {
        tokenResult.errors.forEach(error => {
            Toast.open({ message: error.message, type: 'is-danger', queue: false, duration: 5000, position: 'is-bottom' });
        });
    } catch (err) {
        Toast.open({ message: 'Error in tokenize', type: 'is-danger', queue: false, duration: 5000, position: 'is-bottom' });
        throw new Error('Error in tokenize');
    }

}

async function verifyBuyer(CHARGE, payments, token, user) {

    const intent = CHARGE ? 'CHARGE' : 'STORE';

    const verificationDetails = {
        intent: intent,
        billingContact: {
            addressLines: [user.address.address],
            familyName: user.lname,
            givenName: user.fname,
            country: 'GB',
            phone: user.phone,
            region: user.address.city,
            city: user.address.town,
        }
    };
    console.log(CHARGE)
    if (CHARGE) {
        const amountConvert = Dinero({ amount: CHARGE, currency: 'GBP' }).toUnit();

        verificationDetails.amount = String(amountConvert);
        verificationDetails.currencyCode = 'GBP';
    }

    console.log(verificationDetails);

    const verificationResults = await payments.verifyBuyer(token, verificationDetails);
    return verificationResults.token;
}

function buildPaymentRequest(payments, totalAmount) {
    const amountConvert = Dinero({ amount: totalAmount, currency: 'GBP' }).toUnit();

    const request = {
        countryCode: 'GB',
        currencyCode: 'GBP',
        total: {
            amount: `${amountConvert}`,
            label: 'Total'
        }
    }

    console.log(request);
    return payments.paymentRequest(request);
}

export {
    createSquare,
    getCredentials,
    handleSubmission,
    tokenize,
    verifyBuyer,
    buildPaymentRequest
}