<style lang="scss">
@import "../../../assets/css/cardform.scss";
</style>
<style>
#card-container {
  margin-bottom: 0px;
}
</style>
<template>
  <div class="text-light">
    <div class="row">
      <form id="payment-form">
        <div id="card-container"></div>
      </form>
      <content-loader v-if="loading" :width="333" :height="97" :speed="2" primaryColor="#b0b0b0" secondaryColor="#ecebeb">
        <rect x="0" y="0" rx="7" ry="7" width="333" height="97" />
      </content-loader>
    </div>
    <div class="row mt-3">
      <div class="col d-flex justify-content-end">
        <button v-on:click="resetForm()" class="btn btn-danger mr-2" type="button">Cancel</button>
        <button v-on:click="addCard()" class="btn btn-dark" id="card-button" type="button">Add card</button>
      </div>
    </div>
  </div>
</template>


<script>
import { getAuth } from "firebase/auth";
import { ContentLoader } from "vue-content-loader"
import { getDarkMode } from '@assets/js/darkMode';
import { loadUser } from '@assets/js/userController';
import { getRemoteConfigValue } from '@assets/js/remoteConfig';
import { createSquare, getCredentials, handleSubmission } from '@assets/js/square';
export default {
  name: 'CardForm',
  components: {
    ContentLoader
  },
  data() {
    return {
      payments: null,
      card: null,
      user: null,
      loading: true,
      darkModeCardStyle: { '.input-container': { borderColor: '#262626', borderRadius: '6px' }, '.message-text': { color: '#FFFFFF', }, '.message-icon': { color: '#FFFFFF', }, '.message-text.is-error': { color: '#f93154', }, '.message-icon.is-error': { color: '#f93154', }, 'input': { backgroundColor: '#ebebeb', color: '#000000', }, 'input::placeholder': { color: '#747474', }, 'input.is-error': { color: '#f93154', } }
    }
  },
  methods: {
    async userData() {
      const user = this.$store.getters.user;

      if (user == null) {
        const userData = await loadUser();
        this.$store.commit('setUser', userData);
        return userData;
      }

      return user;
    },
    async initializeSquare() {

      const USER = await this.userData();
      const ENVIROMENT = await getRemoteConfigValue('ENVIROMENT');
      const API_CREDS = await getCredentials(ENVIROMENT.asString());

      await createSquare(ENVIROMENT.asString());

      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }

      this.user = USER;
      this.payments = window.Square.payments(API_CREDS.APPID, API_CREDS.LOCATIONID);

      this.initializeCard(this.payments)
        .then((card) => {
          this.card = card;
          this.loading = false;
        })
        .catch((error) => {
          this.$BuefySimplified.Toast.Danger(`Initializing Card failed: ${error}`);
          console.error('Initializing Card failed', error);
        });

    },
    async initializeCard(payments) {
      var options = {};

      if (getDarkMode()) {
        options = {
          style: {
            ...this.darkModeCardStyle
          }
        }
      }

      const card = await payments.card(options);
      await card.attach('#card-container');
      this.loading = false;
      return card;
    },
    async addCard() {
      const token = await handleSubmission(false, this.payments, this.card, true, this.user);
      this.addCardToUser(token.token, token.verificationToken);
    },
    async addCardToUser(token, verificationToken) {
      try {

        const apiURL = await getRemoteConfigValue('APIurl');

        const app = this.$firebaseApp
        const auth = getAuth(app)
        const authtoken = await auth.currentUser.getIdToken();

        const customerID = this.user.square_customer_id;

        let body = JSON.stringify({
          token,
          verificationToken
        });

        const response = await fetch(`${apiURL.asString()}/api/cards/${customerID}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${authtoken}`,
            "csrf-token": this.$cookies.get('XSRF-TOKEN'),
          },
          body
        });

        if (response.status == 200) {
          this.$BuefySimplified.Toast.Success('Card added successfully');
          this.$router.push('/account/cards');
          return;
        }

        this.$BuefySimplified.Toast.Danger('Card failed to add');
        this.resetForm();

      } catch (e) {
        this.$BuefySimplified.Toast.Danger(`Adding card failed: ${e}`);
        this.resetForm();
      }

    },
    resetForm() {
      this.card.destroy();
      this.loading = true;

      setTimeout(() => {
        this.initializeSquare();
      }, 1000);
    },
  },
  mounted() {
    console.log(this.$BuefySimplified)
    this.initializeSquare();
  },
}
</script>